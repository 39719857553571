<template>
  <div id="our-team">
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == false" class="col-12 mt-5 mb-5">
        <section class="our-team-outer padding-lg grey-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                    <h1 class="text-uppercase mb-3">{{ $t('Meet Our Team') }}</h1>
                    <p>{{ $t('Meet our dedicated team members who provide the overall strategic direction and purpose to develop programs and services that serve our mission of sharing the Traditional Indian Yoga Knowledge and creating a healthy living community worldwide.') }}</p>
                </div>
                 <hr class="col-12 mb-5" />
              </div>
                <h2 class="mb-5">{{ $t('Board of Director') }}</h2>
                <ul class="row team-listing pl-0 pr-0">
                  <li v-for="(item) in boardOfDirector" :key="item.name" class="col-md-6 col-lg-4 mb-3">
                    <figure>
                      <b-avatar variant="light" center :src="require(`../../assets/images/team/${item.img}`)" alt="Left image" size="8rem"></b-avatar></figure>
                      <h3 class="text-uppercase">{{ item.name }}</h3>
                      <span class="source-title">{{ item.position }}</span>
                      <p class="desc">{{ item.desc }}</p>
                      <ul class="follow-us d-none">
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                      </ul>
                  </li>
                </ul>
                <h2 class="mb-5">{{ $t('Our International Director') }}</h2>
                <ul class="row team-listing pl-0 pr-0">
                  <li v-for="(item) in ourInternationalDirector" :key="item.name" class="col-md-6 col-lg-4 mb-3">
                    <figure>
                      <b-avatar variant="light" center :src="require(`../../assets/images/team/${item.img}`)" alt="Left image" size="8rem"></b-avatar></figure>
                      <h3 class="text-uppercase">{{ item.name }}</h3>
                      <span class="source-title">{{ item.position }}</span>
                      <p class="desc">{{ item.desc }}</p>
                      <ul class="follow-us d-none">
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                      </ul>
                  </li>
                </ul>
                <h2 class="mb-5 d-none">{{ $t('Official Team Member') }}</h2>
                <ul class="row team-listing pl-0 pr-0 d-none">
                  <li v-for="(item) in officeTeamMember" :key="item.name" class="col-md-6 col-lg-4 mb-3">
                    <figure>
                      <b-avatar variant="light" center :src="require(`../../assets/images/team/${item.img}`)" alt="Left image" size="8rem"></b-avatar></figure>
                      <h3>{{ item.name }}</h3>
                      <span class="source-title">{{ item.position }}</span>
                      <p class="desc">{{ item.desc }}</p>
                      <ul class="follow-us d-none">
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                          <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                      </ul>
                  </li>
                </ul>
                <!-- <ul class="row team-listing pl-0 pr-0 d-none">
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar  variant="light" center :src="require('../../assets/images/board/Our-Board-Guru-Dharm.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>GURU DHARM</h3>
                        <span class="source-title">{{ $t('Community President and Founder') }}</span>
                        <p class="desc">{{ $t('Guru Dharm started his Yoga path at the age of four. He loves sharing the spiritual wisdom and traditional yoga knowledge of what he had learned from his Guru “Swami Abhedananda Giri ji Maharaj”, a Monk from India. He is always working on how he can truly help individuals and society through the yogic lifestyles. As Yoga is a heritage of traditional Indian Culture that’s why he came up with an idea of bringing people together to help building yoga community around the world. Finally, he and his team established WYA organization to maintain the yoga education quality and standards worldwide.') }}</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-Sajjan-Kaur.jpeg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>MRS SAJJAN KAUR</h3>
                        <span class="source-title">Development</span>
                        <p class="desc">Considering the need of Indian yoga culture and its society, I’ve been working hard with my team to empower our worldwide community for yoga schools and teachers since November 2011. We started the organisation with an ideal of sharing ..</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-Anand-Singh.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>CHODHARY ANAND SINGH</h3>
                        <span class="source-title">Governance Committee Member and Co-Founder</span>
                        <p class="desc">Anand Ji always has a vision to sharing the truth and helping the society in all around the India.</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-Acharya-Satyapati.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>ACHARYA SATYAPATI</h3>
                        <span class="source-title">Governance Committee Member & Co-Founder</span>
                        <p class="desc">He is a traditional Sanskrit & Kriya Yoga Master for the past 42 years. He left his home at the age of 13 to enter the pure environment of the Gurukulam. He learnt about Sanskrit Vyakaran..</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-Dr.-Ishwar-Bharadwaj.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>DR. ISHWAR BHARDWAJ – PROFESSOR</h3>
                        <span class="source-title">Education Development Committee Member & Co-Founder</span>
                        <p class="desc">One of the most respected and recognised name in India. He is a yoga professor and head at Gurukul Kangri Vishwavidyalaya (University at Haridwar City) in India. He run the department of Human Consciousness and Yogic Sciences.</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-Panji.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>PANTELIS NASTOS</h3>
                        <span class="source-title">Thailand Division Director</span>
                        <p class="desc">He is always working in the yoga industry with his pure intention. He is great karma and a bhakti yogi in his own path, always follow the yoga path and its lineage. He has been sharing yoga practice since 2010. He is a founder of Triselion Yoga School.</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-ananya.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>MRS. ANANYA TITNANTABUT</h3>
                        <span class="source-title">Thailand Division Director</span>
                        <p class="desc">{{ $t('“Director and a representative of World Yoga Alliance” in Thailand. She is also the founder of') }} <a href="https://www.wya-thailandyoga.com/" target="_blank">“WYA Thailand Yoga Community”.</a> {{ $t('She is an experienced Yoga Teacher, and one of the main yoga teachers to organize events with all educational standards of WYA in Thailand.') }}</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-KruNote.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>MR. WORAPOJ MANIAM (KRU NOTE)</h3>
                        <span class="source-title">Education Development Committee – Exclusive Teacher Trainer</span>
                        <p class="desc">He worked in Thailand as a Certified and Registered Yoga Teacher by World Yoga Alliance and currently teaching in Professional Yoga classes in Bangkok at My Peace Yoga. He has yoga teaching experience more than 12 years.</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/board/Our-Board-KruOh.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>MONCHAYA PLOYLEARMSANG (KRU OH)</h3>
                        <span class="source-title">Standard Management Committee – Exclusive Anatomy Teacher Trainer</span>
                        <p class="desc">She is an Exclusive Yoga Anatomy Teacher Trainer and teaching professional yoga classes and Anatomy yoga trainings in all around the world.</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5 d-none">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/testimonial-thumb.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>The Example</h3>
                        <span class="source-title">Creative Director</span>
                        <p class="desc">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                    <li class="col-md-6 col-lg-3 mb-5 d-none">
                        <figure>
                        <b-avatar variant="light" center :src="require('../../assets/images/testimonial-thumb.jpg')" alt="Left image" size="8rem"></b-avatar></figure>
                        <h3>The Example</h3>
                        <span class="source-title">Creative Director</span>
                        <p class="desc">Lorem Ipsum is simply dummy text of the printing and typesetting Lorem Ipsum is simply dummy text of the printing</p>
                        <ul class="follow-us d-none">
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'facebook-f']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'instagram']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'twitter']"/></span></a></li>
                            <li><a href="#" target="_blank" class="text-white"><span class=""><font-awesome-icon :icon="['fab', 'google']"/></span></a></li>
                        </ul>
                    </li>
                </ul> -->
            </div>

        </section>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'OurTeam',
  title: ' | Our Team',
  data () {
    return {
      loading: true,
      boardOfDirector: [
        {
          img: 'Guru_Dharm-v1.png',
          name: 'VIDAAN SINGH',
          position: 'Founder and President',
          desc: 'Master started his Yoga path at the age of four. He loves sharing the spiritual wisdom and traditional yoga knowledge of what he had learned from his Guru “Swami Abhedananda Giri ji Maharaj”, a Monk from India. He is always working on how he can truly help individuals and society through the yogic lifestyles. As Yoga is a heritage of traditional Indian Culture that’s why he came up with an idea of bringing people together to help building yoga community around the world. Finally, he and his team established WYA organization to maintain the yoga education quality and standards worldwide.'
        },
        {
          img: 'Sajjan_Kaur.jpeg',
          name: 'MRS SAJJAN KAUR',
          position: 'Co-Founder and Director of WYA India',
          desc: 'Considering the need of Indian yoga culture and its society, Sajjan Kaur has been working hard with the team to empower our worldwide community for yoga schools and teachers since November 2011. She is one of the most supportive co-founder helping the organization with an ideal of sharing “One Passion, One Community and One Place” in the name of World Yoga Alliance.'
        },
        {
          img: 'Anand_Singh.jpg',
          name: 'CHODHARY ANAND SINGH',
          position: 'Governance Committee Member and Co-Founder',
          desc: 'Anand Ji always has a vision to sharing the truth and helping the society in all around India. He has been practicing yoga for the past 38 years as a traditional Karma Yogi as well as working for human resources and Indian national society with a national political party called as Aap. He has the great experience in social science.'
        },
        {
          img: 'Acharya_Satyapati.jpg',
          name: 'ACHARYA SATYAPATI',
          position: 'Governance Committee Member & Co-Founder',
          desc: 'Leaving his home at the age of 13 to enter the pure environment of the Gurukulam, Acharya Stayapati Ji has been working as a traditional Sanskrit & Kriya Yoga Master for the past 42 years. He studied Sanskrit Vyakaran Acharya from the Arsh study. He had a great yoga teaching experience with great yogis all around India. He is also working with India’s most famous and spiritual Yogis.'
        },
        {
          img: 'Dr_Ishwar_Bharadwaj.jpg',
          name: 'DR. ISHWAR BHARDWAJ – PROFESSOR',
          position: 'Education Development Committee Member & Co-Founder',
          desc: 'One of the most respected and well-known yoga professor in India. He is head of Gurukul Kangri Vishwavidyalaya, the University located in Haridwar City, INDIA. He has been working at the department of Human Consciousness and Yogic Sciences established in 1984.'
        },
        {
          img: 'Anna.jpg',
          name: 'ANNA - ANJANI CHAUDHARY',
          position: 'Administrative Assistant',
          desc: 'Anna is one of our team member based in India. She has been carrying on our mission and vision to promote and support our members worldwide with her nice and friendly attitude'
        }
      ],
      ourInternationalDirector: [
        {
          img: 'Saruda-v1.png',
          name: 'MRS SARUDAR SINGH',
          position: 'MANAGING DIRECTOR - THAILAND',
          desc: 'Saruda started yoga since her childhood She has been offering gentle yoga and mindfulness meditation as a community of practices at her workplace to promote mindfulness, self-care and compassion. Saruda is well qualified E-RTY 500 Hrs yoga teacher specializing in the philosophy and psychology of yoga science. She has been working with her deep understanding for yoga knowledge, time to time she conducts free WYA workshops in Thailand.'
        },
        {
          img: 'Panji.jpg',
          name: 'PANTELIS NASTOS',
          position: 'Managing Director – Greece',
          desc: 'Pan Ji always has pure intention to work in the yoga community. Over ten years, he has been following his own path being great karma & bhakti yogi and always follow the yoga path and its lineage. In 2019, Pan Ji established his own yoga school named “Triselion Yoga School” in Greece, aiming to share his traditional yoga knowledge to the community.'
        },
        {
          img: 'Dimitris_Cyprus.jpeg',
          name: 'Dimitris Papapetrou',
          position: 'MANAGING DIRECTOR – CYPRUS',
          desc: 'WYA Yoga Community is working to offer high quality services in Europe and beyond. We are the people who want to share the yoga knowledge with others, provide them privileges as well as helping them to become healthier not only physically but also mentally and spiritually because Yoga is an absolute lifestyle to achieve happy life with healthy body and mind. If you are a yoga teacher or school, I really love to see you as a part of our WYA Community'
        },
        /*{
          img: 'Luigi_Italy.jpeg',
          name: 'Luigi Xuereb',
          position: 'MANAGING DIRECTOR - ITALY',
          desc: 'We have developed an innovative way of living and communicating, many years of experience, travel, meetings, seminars and studies and today is an efficient method which can improve your life and the lives of others.  With many thanks to our team of professionals who constantly keep themselves informed & up to date, we managed to create a trusted, effective and guaranteed method.'
        },*/
        {
          img: 'Madelyn_Cecily_Ryan.jpeg',
          name: 'Madelyn Cecily Ryan',
          position: 'ADMINISTRATIVE OFFICER - USA',
          desc: 'I am Madelyn Cecily Ryan from Colorado, USA. Yoga has become so much more than just a hobby or even a job. Yoga has become my life and I am forever grateful for my Guru who teaching me Real Traditional India Yoga. When I teach and share this practice it feels so purposeful, like I am sharing truth and light from 100s of 1000s of years from Lord Shiva, the founder of Yoga, himself. We love to see you at WYA.'
        },
        {
          img: 'RabihElMdawar-v1.png',
          name: 'RABIH',
          position: 'MANAGING DIRECTOR - LEBANON',
          desc: 'Rabih ElMdawar is a seasoned Yoga teacher with extensive training in Ashtanga Vinyasa, Hatha Yoga, and Yoga as Therapy, over 5000 hours of teaching experience, Passionate about integrating yoga philosophy into everyday life, Rabih believes in its practical application as a solution to modern challenges.'
        },
        {
          img: 'Sirichon-v1.png',
          name: 'SIRICHON BUERGER',
          position: 'MANAGING DIRECTOR - GERMANY',
          desc: "Most importantly, I have to say that I love my job as a body therapist very much... Working on human bodies is the most valuable and beautiful job I could ever imagine. That's why I started looking for ways to keep myself happy and healthy so that I can do my job until I don't want to anymore. Have done 500 hrs Certified Yoga teacher training course but i am always a student."
        }
      ],
      officeTeamMember: [
        // {
        //   img: 'Monika.jpg',
        //   name: 'MONIKA - MANWIKA TANTIVISUT',
        //   position: 'Administrative Manager',
        //   desc: 'Monika has passion for Yoga and she is willing to help building supportive yoga community. So far she has been doing her best to support our members and also those who would like to join the organization whenever they need any assistance.'
        // },
        // {
        //   img: 'Anna.jpg',
        //   name: 'ANNA - ANJANI CHAUDHARY',
        //   position: 'Administrative Assistant',
        //   desc: 'Anna is one of our team member based in India. She has been carrying on our mission and vision to promote and support our members worldwide with her nice and friendly attitude'
        // },
        // {
        //   img: 'Shivani.jpg',
        //   name: 'SHIVANI - PATCHARAPAN SAIJUNTRA',
        //   position: 'Administrative Assistant',
        //   desc: 'Shivani is an enthusiastic and dedicated person. She always do her best to apply her Foundational Yoga Knowledge along with her personal skills to fulfil our mission providing friendly support for our community.'
        // },
        // {
        //   img: 'Madelyn_Cecily_Ryan.jpeg',
        //   name: 'Madelyn Cecily Ryan',
        //   position: 'ADMINISTRATIVE OFFICER',
        //   desc: 'I am Madelyn Cecily Ryan from Colorado, USA. Yoga has become so much more than just a hobby or even a job. Yoga has become my life and I am forever grateful for my Guru who teaching me Real Traditional India Yoga. When I teach and share this practice it feels so purposeful, like I am sharing truth and light from 100s of 1000s of years from Lord Shiva, the founder of Yoga, himself. We love to see you at WYA.'
        // },
        // {
        //   img: 'Kru_Note.jpg',
        //   name: 'KRU NOTE - MR. WORAPOJ MANIAM',
        //   position: 'Education Development & Exclusive Teacher Trainer',
        //   desc: 'With his teaching experience more than 12 years, Kru Note has been working as a Certified and Registered Yoga Teacher with WYA Community and giving support in teaching, developing and maintaining yoga education standards in Thailand. He is also founder of famous yoga school in Bangkok named “My Peace Yoga”.'
        // },
        // {
        //   img: 'Kru_Oh.jpg',
        //   name: 'KRU OH - MONCHAYA PLOYLEARMSANG',
        //   position: 'Standard Management & Exclusive Anatomy Teacher Trainer',
        //   desc: 'Kru Oh is a Certified and Registered Yoga Teacher with WYA. Specialized in Anatomy, now she is working as WYA Exclusive Yoga Anatomy Trainer and teaching professional yoga classes and Anatomy yoga trainings around the world.'
        // }
      ]
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style scoped>
  #our-team {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
.desc {
  text-align: justify;
}
</style>
